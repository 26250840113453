import dayjs from 'dayjs';
import { IHotel } from 'app/shared/model/hotel.model';
import { Language } from 'app/shared/model/enumerations/language.model';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface IContract {
  id?: string;
  contractCode?: string | null;
  contractName?: string | null;
  contractNumber?: string | null;
  signDate?: string | null;
  activationDate?: string | null;
  expire?: string | null;
  pretaxValue?: number | null;
  tax?: number | null;
  totalValue?: number | null;
  unit?: Language | null;
  status?: Status | null;
  fileUpload?: string | null;
  description?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isDeleted?: boolean | null;
  hotel?: IHotel | null;
  fileUploadExt?: string | null;
}

export const defaultValue: Readonly<IContract> = {
  isDeleted: false,
};
