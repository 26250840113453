import dayjs from 'dayjs';
import { IMovie } from 'app/shared/model/movie.model';
import { IMenuService } from 'app/shared/model/menu-service.model';
import { TypeMovie } from 'app/shared/model/enumerations/type-movie.model';
import { Language } from 'app/shared/model/enumerations/language.model';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface ISeriesMovie {
  id?: string;
  name?: string;
  type?: TypeMovie | null;
  episode?: string | null;
  evaluate?: number | null;
  reviewer?: number | null;
  duration?: number | null;
  author?: string | null;
  region?: string | null;
  year?: string | null;
  director?: string | null;
  actor?: string | null;
  price?: number | null;
  unit?: Language | null;
  status?: Status | null;
  description?: string | null;
  thumbnailUrl?: string | null;
  videoUrl?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isDeleted?: boolean | null;
  movies?: IMovie[] | null;
  menuService?: IMenuService | null;
  thumbnailUrlExt?: string | null;
  videoUrlExt?: string | null;
}

export const defaultValue: Readonly<ISeriesMovie> = {
  isDeleted: false,
};
