import dayjs from 'dayjs';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface IChannel {
  id?: string;
  name?: string | null;
  title?: string | null;
  description?: string | null;
  thumbnailUrl?: string | null;
  status?: Status | null;
  videoUrl?: string | null;
  author?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isDeleted?: boolean | null;
  thumbnailUrlExt?: string | null;
  videoUrlExt?: string | null;
}

export const defaultValue: Readonly<IChannel> = {
  isDeleted: false,
};
