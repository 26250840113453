import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { GetProp, TableProps } from 'antd';
import { Select, Table } from 'antd';
import { AUTHORITIES, STATUS_ENUM } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getHotels } from 'app/entities/hotel/hotel-extend.reducer';
import { Language } from 'app/shared/model/enumerations/language.model';
import { Status } from 'app/shared/model/enumerations/status.model';
import { ITEMS_PER_PAGE_MIN } from 'app/shared/util/pagination.constants';
import { EPath } from 'app/utils/constants/EPath';
import { omit, uniqBy } from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import { Translate, ValidatedField, ValidatedForm, translate, Storage } from 'react-jhipster';
import { NumericFormat } from 'react-number-format';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAllChannelActive } from '../channel/channel-extend.reducer';
import {createEntity, getEntity, getOrderAvailable, reset, updateEntity} from './channel-pack-master-extend.reducer';
import TableMovie from './components/table-movie';
import { spinLoading, spinUnLoading } from 'app/shared/reducers/fallback-reducer';
import breadcrumbItem from 'app/components/Breadcrumb/BreadcrumbItem';
import value from "*.json";

type ColumnsType<T> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface DataTypeChannel {
  id: string;
  order: number;
  name: string;
  thumbnailUrl: string;
  status: Status;
}

interface DataTypeMovie {
  id: string;
  order: number;
  type: string;
  name: string;
  menuService: {
    name: string;
  };
  thumbnailUrl: string;
  status: Status;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

export const ChannelPackMasterUpdateExtend = () => {
  const role = Storage.session.get('role');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const { idChannelPackMaster } = useParams<'idChannelPackMaster'>();
  const isNew = role.includes(AUTHORITIES.ADMIN) ? idChannelPackMaster === undefined : id === undefined;
  const hotels = useAppSelector(state => state.hotelExtend.entities);
  const channelPackMasterEntity = useAppSelector(state => state.channelPackMasterExtend.entity);

  const updating = useAppSelector(state => state.channelPackMasterExtend.updating);
  const updateSuccess = useAppSelector(state => state.channelPackMasterExtend.updateSuccess);

  const loadingChannel = useAppSelector(state => state.channelExtend.loading);
  const channelList = useAppSelector(state => state.channelExtend.entities);
  const totalsChannel = useAppSelector(state => state.channelExtend.totalItems);
  const numbersAvailable = useAppSelector(state => state.channelPackMasterExtend.orders);
  const statusValues = Object.keys(Status);

  const [dataChannel, setDataChannel] = useState<DataTypeChannel[]>([]);
  const [mapDataChannel, setMapDataChannel] = useState<DataTypeChannel[]>([]);
  const [dataChannelUpdate, setDataChannelUpdate] = useState([]);
  const [dataMovie, setDataMovie] = useState([]);
  const [dataMovieEN, setDataMovieEN] = useState([]);
  const [dataMovieJA, setDataMovieJA] = useState([]);
  const [dataMovieKO, setDataMovieKO] = useState([]);

  const [showBtnSubmit, setShowBtnSubmit] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const [tableParamChannel, setTableParamChannel] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: ITEMS_PER_PAGE_MIN,
    },
  });
  const [order, setOrder] = useState([]);
  const columnChannel: ColumnsType<DataTypeChannel> = [
    {
      title: <Translate contentKey="hotelTvApp.channel.stt">STT</Translate>,
      dataIndex: 'order',
      render: (_, record) => (
        <Select
          defaultValue={record.order}
          style={{ width: 60 }}
          options={order?.map(i => {if (i === 0) {
            return ({ value: null, label: "" })
          }
          else return ({ value: i, label: i })})}
          onChange={e => handleOrderChange(e, record.id, record.order)}
        />
      ),

      width: '15%',
    },
    {
      title: <Translate contentKey="hotelTvApp.channel.name">Name</Translate>,
      dataIndex: 'name',
      width: '35%',
    },
    {
      title: <Translate contentKey="hotelTvApp.channel.thumbnailUrl">Thumbnail Url</Translate>,
      dataIndex: 'thumbnailUrl',
      align: 'center',
      render: url => {
        if (url) {
          return <img style={{ width: '100px', height: '50px', objectFit: 'cover' }} src={url} alt="img_channel" />;
        }
      },
      width: '35%',
    },
    {
      title: <Translate contentKey="hotelTvApp.channel.status">Status</Translate>,
      dataIndex: 'status',
      render: (_, record) => (
        <Select
          defaultValue={record.status}
          style={{ width: 130 }}
          options={STATUS_ENUM.map(i => ({
            value: i.value,
            label: <p style={{ margin: '0', color: i.value === 'ACTIVATE' ? 'green' : 'red' }}>{i.label}</p>,
          }))}
          onChange={e => handleChangeStatusChannel(e, record.id)}
        />
      ),
      width: '20%',
    },
  ];
  const handleClose = () => {
    history.back();
  };

  useEffect(() => {
    if (isNew){
      setOrder(Array.from({ length: totalsChannel + 1}, (_, i) => i));
    }
    else{
      setOrder(numbersAvailable);
    }
  }, [totalsChannel, numbersAvailable]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  //Live TV start
  const getAllEntities = () => {
    dispatch(
      getAllChannelActive({
        page: tableParamChannel.pagination.current - 1,
        size: tableParamChannel.pagination.pageSize,
      })
    );
  };

  //check channel pack in device
  useEffect(() => {
    if (channelPackMasterEntity.isSet) {
      setShowBtnSubmit(false);
    }
  }, [channelPackMasterEntity.isSet]);

  useEffect(() => {
    getAllEntities();
  }, [tableParamChannel.pagination.current, tableParamChannel.pagination.pageSize]);
  //Live TV end

  useEffect(() => {
    dispatch(reset());
    dispatch(getHotels({}));
    setDataChannelUpdate([]);
    setMapDataChannel([]);
    setDataChannel([]);
    setDataMovie([]);
    setDataMovieEN([]);
    setDataMovieJA([]);
    setDataMovieKO([]);
  }, []);

  //get list Channel in channel pack
  useEffect(() => {
    if (!isNew) {
      dispatch(
        getEntity({
          tvPage: tableParamChannel.pagination.current - 1,
          tvSize: tableParamChannel.pagination.pageSize,
          packId: role.includes(AUTHORITIES.ADMIN) ? idChannelPackMaster : id,
        })
      );
    }
  }, [tableParamChannel.pagination.current, tableParamChannel.pagination.pageSize, id]);

  useEffect(() => {
    if (!isNew) {
      dispatch(
        getOrderAvailable({
          packId: role.includes(AUTHORITIES.ADMIN) ? idChannelPackMaster : id,
        })
      );
    }
  }, [id]);

  //update list channel
  useEffect(() => {
    if (!isNew) {
      setDataChannel(channelPackMasterEntity.channels);
    }
    else{
      setDataChannel(channelList.map(channel => ({ ...channel, status: 'DEACTIVATE' })));
    }
    setTableParamChannel({
      ...tableParamChannel,
      pagination: {
        ...tableParamChannel.pagination,
        total: totalsChannel,
      },
    });
  }, [
    channelList,
    channelPackMasterEntity.channels,
    totalsChannel,
    tableParamChannel.pagination.current,
    tableParamChannel.pagination.pageSize,
  ]);

  useEffect(() => {
    handleMergeDataChannel();
    handleMapDataChannel();
  }, [dataChannel]);

  useEffect(() => {
    setTableParamChannel({
      pagination: {
        current: 1,
        pageSize: tableParamChannel.pagination.pageSize,
      },
    });
  }, [tableParamChannel.pagination.pageSize]);

  const handleMapDataChannel = () => {
    if (dataChannel) {
      const mapDataChannel = dataChannel.map(channel => {
        const existingChannel = dataChannelUpdate?.find(c => c.id === channel.id);
        if (existingChannel) {
          return { ...channel, order: existingChannel.order, status: existingChannel.status };
        }
        return channel;
      });
      setMapDataChannel(mapDataChannel);
    }
  };

  const handleMergeDataChannel = () => {
    if (dataChannel) {
      const newChannels = dataChannel.filter(channel => !dataChannelUpdate?.some(c => c.id === channel.id));
      setDataChannelUpdate([...dataChannelUpdate, ...newChannels]);
    }
  };

  const handleChangeStatusChannel = (e, channelId: string) => {
    const updatedChannels = mapDataChannel.map(channel => {
      if (channel.id === channelId) {
        return { ...channel, status: e };
      }
      return channel;
    });
    const updatedDataChannelUpdate = dataChannelUpdate.map(channel => {
      if (channel.id === channelId) {
        return { ...channel, status: e };
      }
      return channel;
    });
    setDataChannelUpdate(updatedDataChannelUpdate);
    setMapDataChannel(updatedChannels);
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
        status: 'ACTIVATE',
        ...channelPackMasterEntity,
      };

  const handleTableChannel: TableProps['onChange'] = (pagination, filters, sorter) => {
    setTableParamChannel({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParamChannel.pagination?.pageSize) {
      setDataChannel([]);
      setMapDataChannel([]);
    }
  };

  //Handle save all data
  const saveEntity = async values => {
    dispatch(spinLoading());
    const entity = {
      ...channelPackMasterEntity,
      ...values,
      channels: dataChannelUpdate.filter(item => item !== undefined).flat(),
      seriesMovies: uniqBy(
        [
          ...dataMovie.filter(item => item !== undefined).flat(),
          ...dataMovieEN.filter(item => item !== undefined).flat(),
          ...dataMovieJA.filter(item => item !== undefined).flat(),
          ...dataMovieKO.filter(item => item !== undefined).flat(),
        ],
        'id'
      ),
      ...(role.includes(AUTHORITIES.ADMIN) ? { hotelId: id } : {}),
    };

    if (isNew) {
      await dispatch(createEntity(omit(entity, ['hotel', 'id'])));
    } else {
      await dispatch(updateEntity(entity));
    }
    dispatch(spinUnLoading());
  };

  const handleSaveDataFake = () => {
    const element = document.getElementById('save-entity');
    if (element) {
      element.click();
    }
  };
  const updateNumbers = (e, value) => {
    setOrder(prevNumbers => {
      const filteredNumbers = prevNumbers.filter(num => num !== e);

      if (value !== null && value !== undefined) {
        filteredNumbers.push(value);
      }
      return filteredNumbers.sort((a, b) => a - b);
    });
  };
  const handleOrderChange = (e, channelId: string, value?) => {
    updateNumbers(e, value);
    const updatedChannels = dataChannel.map(channel => {
      if (channel.id === channelId) {
        return { ...channel, order: e === 0 ? null : e };
      }
      return channel;
    });
    const updatedDataChannelUpdate = dataChannelUpdate.map(channel => {
      if (channel.id === channelId) {
        return { ...channel, order: e === 0 ? null : e };
      }
      return channel;
    });
    setDataChannelUpdate(updatedDataChannelUpdate);
    setDataChannel(updatedChannels);

    // }
  };

  const handleKeyDown = event => {
    const { key } = event;
    if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Tab') {
      event.preventDefault();
    }
  };

  return (
    <>
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="hotelTvApp.channelPackMaster.home.createOrEditLabel" data-cy="ChannelPackMasterCreateUpdateHeading">
              {isNew ? (
                <Translate contentKey="hotelTvApp.channelPackMaster.home.createLabel">Create a ChannelPackMaster</Translate>
              ) : (
                <Translate contentKey="hotelTvApp.channelPackMaster.home.editLabel">Edit a ChannelPackMaster</Translate>
              )}
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              <ValidatedField
                label={translate('hotelTvApp.channelPackMaster.name')}
                labelClass="required"
                required
                id="channel-pack-master-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: {
                    value: true,
                    message: translate('hotelTvApp.channelPackMaster.message.missName'),
                  },
                }}
              />
              <ValidatedField
                label={translate('hotelTvApp.channelPackMaster.description')}
                id="channel-pack-master-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <ValidatedField
                label={translate('hotelTvApp.channelPackMaster.status')}
                id="channel-pack-master-status"
                name="status"
                data-cy="status"
                type="select"
              >
                {statusValues.map(status => (
                  <option value={status} key={status}>
                    {translate('hotelTvApp.Status.' + status)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('hotelTvApp.channelPackMaster.packageDuration')}
                id="channel-pack-master-packageDuration"
                name="packageDuration"
                data-cy="packageDuration"
                type="text"
                disabled={channelPackMasterEntity.isSet}
                placeholder={
                  channelPackMasterEntity.isSet
                    ? translate('hotelTvApp.channelPackMaster.notiDurationEdit')
                    : translate('hotelTvApp.channelPackMaster.placeholderDuration')
                }
                validate={{
                  maxLength: {
                    value: 5,
                    message: translate('hotelTvApp.channelPackMaster.message.maxLength'),
                  },
                  pattern: {
                    value: /^[1-9]\d*$/,
                    message: translate('hotelTvApp.channelPackMaster.message.pattern'),
                  },
                }}
              />
              <dt className="mb-2">
                <span id="listMovie">
                  <Translate contentKey="hotelTvApp.channelPackMaster.liveTVNumber">List Live Tv</Translate>
                </span>
              </dt>
              {/* ================== Table Channel List Start =================== */}
              <Table
                columns={columnChannel}
                rowKey={record => record.id}
                dataSource={mapDataChannel}
                pagination={{
                  ...tableParamChannel.pagination,
                  showSizeChanger: true,
                  pageSizeOptions: [5, 10, 20, 50],
                }}
                loading={loadingChannel}
                onChange={handleTableChannel}
              />
              {/* ================== Table Channel List End =================== */}
              <dt>
                <span id="listMovie">
                  <Translate contentKey="hotelTvApp.channelPackMaster.movieNumber">List Movies</Translate>
                </span>
              </dt>
              {/* ================== Table Movie List VietNam =================== */}
              <TableMovie
                dataMovie={dataMovie}
                language={Language.VN}
                isNew={isNew}
                packId={role.includes(AUTHORITIES.ADMIN) ? idChannelPackMaster : id}
                setDataMovie={setDataMovie}
              />
              {/* ================== Table Movie List English =================== */}
              <TableMovie
                dataMovie={dataMovieEN}
                language={Language.EN}
                isNew={isNew}
                packId={role.includes(AUTHORITIES.ADMIN) ? idChannelPackMaster : id}
                setDataMovie={setDataMovieEN}
              />
              {/* ================== Table Movie List Japan =================== */}
              <TableMovie
                dataMovie={dataMovieJA}
                language={Language.JA}
                isNew={isNew}
                packId={role.includes(AUTHORITIES.ADMIN) ? idChannelPackMaster : id}
                setDataMovie={setDataMovieJA}
              />
              {/* ================== Table Movie List Korea =================== */}
              <TableMovie
                dataMovie={dataMovieKO}
                language={Language.KO}
                isNew={isNew}
                packId={role.includes(AUTHORITIES.ADMIN) ? idChannelPackMaster : id}
                setDataMovie={setDataMovieKO}
              />
              <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => history.back()} color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                className={showBtnSubmit ? '' : 'hidden'}
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
              <Button
                color="primary"
                className={showBtnSubmit ? 'hidden' : ''}
                data-cy="entityCreateSaveButton"
                type="button"
                disabled={updating}
                onClick={() => setIsOpen(true)}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          </Col>
        </Row>
      </div>
      <Modal isOpen={isOpen}>
        <ModalHeader toggle={() => setIsOpen(false)} data-cy="channelPackMasterSaveDialogHeading">
          <Translate contentKey="hotelTvApp.channelPackMaster.edit.titleConfirm">Confirm the change operation</Translate>
        </ModalHeader>
        <ModalBody id="hotelTvApp.channelPackMaster.delete.question">
          <Translate contentKey="hotelTvApp.channelPackMaster.edit.questionDevice" interpolate={{ id: channelPackMasterEntity.name }}>
            Channel pack is attached to the device, do you want to save your changes?
          </Translate>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsOpen(false)}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button onClick={handleSaveDataFake} color="primary">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="entity.action.save">Save</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ChannelPackMasterUpdateExtend;
