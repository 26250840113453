import dayjs from 'dayjs';
import { ISlideShow } from 'app/shared/model/slide-show.model';
import { IHotel } from 'app/shared/model/hotel.model';
import { Type } from 'app/shared/model/enumerations/type.model';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface IWelcome {
  id?: string;
  title?: string | null;
  imageBackground?: string | null;
  videoUrl?: string | null;
  audioUrl?: string | null;
  contentEn?: string | null;
  contentKo?: string | null;
  contentJa?: string | null;
  contentVi?: string | null;
  maxImageSlide?: number | null;
  type?: Type;
  status?: Status;
  author?: string | null;
  checkInAt?: string | null;
  checkOutAt?: string | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  isDeleted?: boolean | null;
  slideShows?: ISlideShow[] | null;
  slideShowSmalls?: ISlideShow[] | null;
  hotel?: IHotel | null;
  imageBackgroundExt?: string | null;
  videoUrlExt?: string | null;
  audioUrlExt?: string | null;
}

export const defaultValue: Readonly<IWelcome> = {
  isDeleted: false,
};
